/* .c-main {
    background: white
} */

:root {
  --color-1st: #192538;
  --color-2nd: #0088ff;
  --primary-font: "Roboto", sans-serif;
  --color-3nd: #029e4d;
  --primary: #0179ff;
  --Info_Lighten_4: #bce2fb;
  --Info_Darken_4: #0a72e8;
  --teal_Lighten_4: #b2dfdb;
  --teal_Darken_4: #004d40;
}

/* .c-sidebar-brand {
    background: white !important;
} */
p {
  margin: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: var(--color-1st) !important;
}

/* .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle{
    color: var(--color-1st) !important;
} */
/* .c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: var(--color-2nd) !important;
} */

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: white !important;
}

/* .c-sidebar {
    background: var(--color-1st) !important;
} */

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: white !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}

.button-e {
  text-transform: "none";
}

.rt-resizable-header-content {
  /* color: var(--color-3nd); */
  /* color: var(--teal_Darken_4); */
  font-weight: 500;
  font-size: 14px;
}
/* .ReactTable .rt-thead .rt-th.-cursor-pointer{
    background:  var(--teal_Lighten_4);;
} */

.ReactTable .rt-tbody .rt-td {
  color: black;
  font-size: 14px;
  margin: 0;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.ReactTable .rt-tbody .rt-td.center {
  justify-content: center;
}

.MuiButton-root {
  color: var(--color-3nd);
}

.MuiDialogTitle-root {
  background-color: var(--primary);
  color: white;
  height: 50px;
}

.dsg-cell-header {
  color: var(--color-3nd) !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.dsg-input {
  font-size: 14px !important;
}

.MuiTypography-root-MuiDialogTitle-root {
  background-color: #0094d3;
  font-size: 14px;
}

.dsg-add-row-btn {
  font-size: 14px;
  padding: 8px;
}

.dsg-add-row-input {
  font-size: 14px;
  padding: 8px;
}

.MuiAutocomplete-root .MuiAutocomplete-tag {
  margin-top: 10px !important;
}

.MuiDialogContent-root {
  padding: 0 !important;
}

.MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.dsg-cell-disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

/* .container-fluid {
    margin-top: -30px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
} */

/* calendar */
.rbc-toolbar {
  display: none !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  /* background-color: white !important; */
  padding: 1px 4px !important;
  /* color: #029e4d !important;*/
  font-size: 12px !important;
}
.rbc-button-link {
  font-weight: 450;
}
.rbc-off-range-bg {
  background: #f4f1f1 !important;
}
.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
}
.react-calendar-timeline .rct-header-root {
  background: rgb(2, 158, 77) !important;
  background: linear-gradient(
    45deg,
    rgba(2, 158, 77, 1) 0%,
    rgba(3, 119, 115, 1) 50%,
    rgba(4, 94, 139, 1) 70%,
    rgba(4, 93, 140, 1) 87%
  ) !important;
}

.MuiTabPanel-root {
  padding: 0 !important;
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: rgba(255, 255, 255, 0.1);
}
