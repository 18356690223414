.responsive-button-export{
    @media (max-width: 576px) {
        flex-direction: column;;
    }

    @media (max-width: 768px) {
        flex-direction: column;;
    }
}

.responsive-button-export-child{
    @media (max-width: 400px) {
        flex-direction: column;
        gap: 10px
    }
}

.responsive-input-gap{
    @media (max-width: 576px) {
        margin-top: 20px
    }
}