// font-size
.labelListFS {
  color: black;
  font-size: 1.25rem;
  padding: 0.625rem 0rem;
  margin: 0;
  font-weight: 600;
}

.fsFilter {
  label {
    font-size: 14px !important;
    color: black !important;
  }

  font-size: 14px !important;
  color: black !important;
}

.bg-gray {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #59595d;
  }

  .bg-menu {
    background-color: #393a3d !important;
  }

  .rt-thead.-header .rt-tr .rt-th {
    background-color: #e6e8ec !important;
    color: black !important;
  }

  .c-main,
  .bg-list {
    background-color: #eceef1 !important;
    color: black !important;
  }

  .bg-button,
  h2 {
    background-color: #393a3d !important;
    color: white !important;
  }

  .filter-button {
    font-size: 0.9rem !important;
    border: 1px solid #393a3d !important;
    color: #393a3d !important;
  }

  .border-button {
    border: 0.5px solid #393a3d;
    color: #393a3d;
  }

  .dsg-cell-header {
    background-color: #e6e8ec !important;
    color: black !important;

    .dsg-corner-indicator {
      border-bottom: solid 10px black;
    }
  }

  //css MUI table
  .mui-table-th {
    background-color: #e6e8ec !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
  }
}

.bg-turquoise {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #2d3c3e;
  }

  .c-main,
  .bg-list {
    color: black !important;
    background-color: #d7edef !important;
  }

  .bg-menu {
    background-color: #021517 !important;
  }

  .bg-button,
  h2 {
    background-color: #0598a7 !important;
    color: white !important;
  }

  .filter-button {
    border: 1px solid #0598a7 !important;
    color: #016f7a !important;
  }

  .rt-thead.-header .rt-tr .rt-th {
    background-color: #aae0e6 !important;
    color: black !important;
  }

  .border-button {
    border: 0.5px solid #0598a7;
    color: #0598a7;
  }

  .dsg-cell-header {
    background-color: #aae0e6 !important;
    color: black !important;

    .dsg-corner-indicator {
      border-bottom: solid 10px black;
    }
  }

  //css MUI table
  .mui-table-th {
    background-color: #aae0e6 !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
  }
}

.bg-purple {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #343f5d;
  }

  .border-button {
    border: 0.5px solid #5d6b99;
    color: #5d6b99;
  }

  .c-main,
  .bg-list {
    background-color: #d9e1fb !important;
    color: black !important;
  }

  .rt-thead.-header .rt-tr .rt-th {
    background-color: #c1d0f8 !important;
    color: black !important;
  }

  .bg-menu {
    background-color: #0c183d !important;
  }

  .bg-button,
  h2 {
    background-color: #5d6b99 !important;
    color: white !important;
  }

  .filter-button {
    border: 1px solid #5d6b99 !important;
    color: #283c7e !important;
  }

  .dsg-cell-header {
    background-color: #c1d0f8 !important;
    color: black !important;

    .dsg-corner-indicator {
      border-bottom: solid 10px black;
    }
  }

  //css MUI table
  .mui-table-th {
    background-color: #c1d0f8 !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
  }
}

.bg-green {

  .c-main,
  .bg-list {
    background-color: #e4e9e7 !important;
    color: black !important;
  }

  .rt-thead.-header .rt-tr .rt-th {
    background-color: #bdd3b9 !important;
    color: black !important;
  }

  .bg-menu {
    background-color: #010a01 !important;
  }

  .border-button {
    border: 0.5px solid #2aa11c;
    color: #2aa11c;
  }

  .bg-button,
  h2 {
    background-color: #2aa11c !important;
    color: white !important;
  }

  .filter-button {
    border: 1px solid #2aa11c !important;
    color: #117c05 !important;
  }

  .dsg-cell-header {
    background-color: #bdd3b9 !important;
    color: black !important;

    .dsg-corner-indicator {
      border-bottom: solid 10px black;
    }
  }

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #2c332e;
  }

  //css MUI table
  .mui-table-th {
    background-color: #bdd3b9 !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
  }
}

.bg-black-white {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #e5f1ff;
  }

  .c-active {
    background-color: #e5f1ff !important;
  }

  .c-header {
    // border: 0.5px solid #eceef1;
    // border-left: none;
    // background-color: #006698;
    border: none;
    // color: white;
    // .c-header-toggler-icon,
    // .c-header-nav-link {
    //   background-color: white;
    // }
  }

  .bg-menu {
    background-color: white !important;
    color: black !important;
    border: 0.5px solid #eceef1;

    a,
    svg {
      color: black !important;
    }
  }

  .rt-thead.-header .rt-tr .rt-th {
    background-color: #e6e8ec !important;
    color: black !important;
  }

  .c-main,
  .bg-list,
  .c-app,
  body {
    background: #ebedef !important;
    color: black !important;
  }

  .c-sidebar .c-sidebar-minimizer {
    background-color: white;
    border-right: 0.5px solid #eceef1;

    &:hover {
      background-color: #e5f1ff;
      color: black;

      &::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 11 14%27%3E%3Cpath fill=%27%238a93a2%27 d=%27M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z%27/%3E%3C/svg%3E");
      }
    }
  }

  .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
    filter: brightness(0) saturate(100%) invert(0%) sepia(92%) saturate(7472%) hue-rotate(252deg) brightness(96%) contrast(95%);
  }

  .c-sidebar-nav-dropdown-items {
    background-color: white;
  }

  .bg-button,
  h2 {
    background-color: #006698 !important;
    color: white !important;
  }

  .bg-button {
    border: none;

    &:hover {
      border: none;
    }
  }

  .filter-button {
    border: 1px solid #006698 !important;
    color: #023e5c !important;
  }

  // .bg-button {
  //   background-color: white;
  //   color: #393a3d;
  //   border: 1px solid #393a3d;
  // }

  .border-button {
    border: 0.5px solid #006698;
    color: #006698;
  }

  .dsg-cell-header {
    background-color: #eceef1 !important;
    color: black !important;

    .dsg-corner-indicator {
      border-bottom: solid 10px black;
    }
  }

  //css MUI table
  .mui-table-th {
    background-color: #e6e8ec !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
  }

  //update background parent menu when color is bg-black-white
  .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
    background-color: #fff;
  }
}

.sizeColor {
  height: 30px;
  width: 30px;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.boxColor {
  text-align: center;
  background: #8080800e;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2 ease-in;
  box-shadow: rgba(100, 100, 111, 0.2) 4px 6px 0px 0px;
  opacity: 0.7;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 4px 6px 0px 0px;
    opacity: 1;
  }

  &:active {
    transform: translate(5px, 6px);
    box-shadow: none;
    opacity: 1;
  }
}

.boxColorActive {
  box-shadow: none !important;
  opacity: 1;

  &:active {
    transform: translate(0, 0);
    box-shadow: none;
    opacity: 1;
  }
}

.c-sidebar-brand {
  background-color: transparent !important;
}

.center-header {
  .dsg-container {
    .dsg-cell-header {
      text-align: center;
    }
  }
}

.dsg-container {
  .padding-0 {
    padding: 0 !important;
  }

  .dsg-cell {
    // padding: 5px; //select MUI không đồng điều
    font-size: 13px;
    padding: 0 4px;
  }

  .dsg-cell-header {
    padding: 0;
    font-size: 14px !important;
  }
}

.c-sidebar {
  width: 220px;
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
    margin-left: 200px;
  }

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper {
    margin-left: 44px;
  }
}

.c-sidebar-nav-link {
  white-space: pre-wrap;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  padding: 10px;
}

@media (min-width: 992px) {
  .c-sidebar-minimized.c-sidebar-fixed {
    width: 44px;
  }
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(90deg);
}

.status-waitting {
  color: orange !important;
  font-weight: 450;
}

.status-approve {
  color: rgba(141, 97, 15, 0.637) !important;
  font-weight: 450;
}

.status-liquidated {
  color: rgb(139, 116, 72) !important;
  font-weight: 450;
}

.status-processing {
  color: #4093d1 !important;
  font-weight: 450;
}

.status-done {
  color: rgb(2, 158, 77) !important;
  font-weight: 450;
}

.status-cancel {
  color: black !important;
  font-weight: 450;
}

.status-lock {
  color: rgb(255, 0, 0) !important;
  font-weight: 450;
}

.status-checked {
  color: rgb(12 117 121) !important;
  font-weight: 450;
}

.status-pickup {
  color: #66CDAA !important;
  font-weight: 450;
}

.status-delivered {
  color: rgb(2, 73, 36) !important;
  font-weight: 450;
}

.bg-menu {
  a {
    font-size: 14px;
  }
}

.ReactTable {
  .rt-resizable-header-content {
    font-size: 14px !important;
  }

  .rt-td {
    font-size: 14px !important;
  }

  .rt-thead.-header {
    box-shadow: none;
  }
}

table td {
  font-size: 14px !important;
}

.makeStyles-root-1 .MuiInputLabel-root,
button,
a.dropdown-item,
label.mb-0.ml-1,
.border-button,
table th,
.textLabel {
  font-size: 0.9rem !important;
  background-color: white;
  padding-right: 10px;
}

.css-uya1ue-MuiTypography-root-MuiDialogTitle-root {
  font-size: 20px !important;
}

.button-outlined-blue {
  color: #0179ff !important;
  border: #0179ff 1px solid !important;
}

.button-outlined-green {
  color: #1b5e20 !important;
  border: #1b5e20 1px solid !important;
}

.button-outlined-red {
  color: #D32F2F !important;
  border: #D32F2F 1px solid !important;
}

.center-header {
  .dsg-container .dsg-cell-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps__rail-x,
.ps__rail-y {
  top: 0 !important;
  height: 0 !important;
}

.expected-revenue-box {
  .item {
    width: 100%;
    margin: 0 10px;

    &-multiplication {
      max-width: 160px;
    }

    &-commodityUnit {
      max-width: 100px;
    }

    &-equal {
      max-width: 40px;
      text-align: center;
    }
  }
}

.size-small {
  // background-color: red;

  //css MUI table
  .mui-table-th {
    background-color: #e6e8ec !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 4px !important;
  }

  table td {
    padding: 0 !important;
  }

  table th {
    font-size: 14px !important;
    padding: 0 !important;
  }

  table {
    .bg-gr {
      background: #f7f9fc !important;
    }
  }

  .tb-bg-gr {
    tr:nth-child(2n) {
      background: #f7f9fc !important;
    }
  }
}

// custom data-sheet
.data-sheet {
  .dsg-row .dsg-input {
    padding: 0 0 0 2px !important;

    .css-1fdsijx-ValueContainer {
      padding: 0 !important;
    }

    .css-1xc3v61-indicatorContainer {
      padding: 0 2px !important;
    }

    svg {
      display: none !important;
      ;
    }
  }
}

.data-sheet-sticky-right {
  .dsg-cell-sticky-right {
    width: 50px !important;
  }
}

//new
.scrollbar {
  float: left;
  height: 300px;
  width: 65px;
  overflow-y: scroll;
}

#style-scrollbar-datasheet::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

#style-scrollbar-datasheet::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

#style-scrollbar-datasheet::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.custom-table {
  border-radius: 12px 12px 0 0;

  table thead.ant-table-thead tr th {
    padding: 8px 16px !important;
  }

  .ant-table-expanded-row .ant-table-cell {
    padding: 0;
  }

  .ant-table-container {
    border-start-start-radius: 12px;
    border-start-end-radius: 12px;
    border-radius: 50%;

    .ant-table-tbody .ant-table-row:last-child .ant-table-cell:first-child {
      border-bottom-left-radius: 12px;
    }

    .ant-table-tbody .ant-table-row:last-child .ant-table-cell:last-child {
      border-bottom-right-radius: 12px;
    }

    .ant-table-tbody .ant-table-cell-row-hover {
      background-color: #E4E7EC;
    }

    .ant-table-tbody .warning-row .ant-table-cell-row-hover {
      background-color: #FEDAD8;
    }

    .ant-table-tbody .ant-table-row td {
      font-weight: 500;
    }

    table thead.ant-table-thead tr:first-child th:first-child {
      border-start-start-radius: 12px;
    }

    table thead.ant-table-thead tr:first-child th:last-child {
      border-start-end-radius: 12px;
    }

    .ant-table-thead tr th {
      padding: 9px 16px;
      color: rgba(255, 255, 255, 0.88);

      .ant-checkbox-checked {
        border: 1px white solid;
      }
    }
  }

  .ant-table-tbody {
    .splitCell {
      padding: 0;
    }
  }

  .ant-table-tbody>tr {
    >td {
      border-right: solid 0.5px #98A2B3;
      height: 32px;
      padding: 0 16px;
      color: rgb(71, 84, 103) !important;
      font-weight: 400;

      .ant-table-row-expand-icon {
        background-color: #4479c5;

        &::before,
        &::after {
          background: white;
        }
      }
    }

    >td:is(:first-child) {
      border-left: solid 0.5px #eaf0ff;
    }
  }

  .ant-table-tbody tr td {
    border-right-color: #98A2B3;
    border-left-color: #98A2B3;
  }

  .ant-table-tbody tr:last-child td {
    border-bottom-color: #eaf0ff;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 100%;
  }

  .ant-table-summary td {
    border-top: 2px solid #98A2B3;
  }

  .ant-select .ant-select-selection-placeholder {
    color: rgb(112, 126, 147) !important;
  }

  .ant-table.ant-table-small .ant-table-tbody>tr>td {
    padding: 8px 16px;
  }
}

.table-holiday {
  .ant-table.ant-table-small .ant-table-tbody>tr>td {
    padding: 0;
  }
}

.first-column {
  padding: 8px 16px !important;
}

.ant-btn-primary:disabled {
  background: #D0D5DD !important;
  color: #98A2B3 !important;
}

.ant-input-outlined.ant-input-disabled {
  color: rgb(112, 126, 147);
}

.ant-select-outlined.ant-select-disabled {
  .ant-select-selector {
    .ant-select-selection-item {
      color: rgb(112, 126, 147);
    }
  }
}

.ant-picker-outlined.ant-picker-disabled {
  .ant-picker-input {
    input {
      color: rgb(112, 126, 147);
    }
  }
}

.ant-checkbox-wrapper-disabled {
  span {
    color: rgb(112, 126, 147)
  }
}

.card-content {
  .ant-space-align-center:nth-child(2) {
    align-items: unset !important;
  }
}

.card-history-timekeeping {
  .ant-card-body {
    padding: 16px
  }
}

.icon-hidden {
  display: none;
}

.parent-icon:hover .icon-hidden {
  display: block;
}

.warning-row {
  background-color: #FEE4E2;
}

.radio-warning {
  color: #ff4d4f;
}

.custom-table-timekeeping,
.custom-table-timekeeping-disable {
  border-radius: 12px 12px 0 0;

  .ant-table-expanded-row .ant-table-cell {
    padding: 0;
  }

  .ant-table-container {
    border-radius: 12px 12px 0 0;

    .ant-table-tbody .ant-table-row td {
      font-weight: 500;
    }

    .ant-table-tbody .ant-table-row .ant-table-cell-fix-left {
      padding: 0 16px;
    }

    table thead.ant-table-thead tr:first-child th:first-child {
      border-top-left-radius: 12px;
    }

    table thead.ant-table-thead tr:first-child th:last-child {
      border-top-right-radius: 12px;
    }

    .ant-table-thead tr th {
      padding: 9px 16px;
      background-color: #4479c5;
      color: rgba(255, 255, 255, 0.88);

      .ant-checkbox-checked {
        border: 1px solid white;
      }
    }

    .ant-table-thead tr th.clicked-column {
      padding: 0px 16px;
      background-color: #314D99;
      color: rgba(255, 255, 255, 0.88);
    }
  }

  .ant-table-tbody {
    .splitCell {
      padding: 0;
    }
  }

  .ant-table-tbody>tr {
    >td {
      border-right: 0.5px solid #98A2B3;
      height: 32px;
      padding: 0;
      font-weight: 400;

      .ant-table-row-expand-icon {
        background-color: #4479c5;

        &::before,
        &::after {
          background: white;
        }
      }
    }

    >td:is(:first-child) {
      border-left: 0.5px solid #eaf0ff;
    }
  }

  .ant-table-tbody tr td {
    border-right-color: #98A2B3;
    border-left-color: #98A2B3;
  }

  .ant-table-tbody tr:last-child td {
    border-bottom-color: #eaf0ff;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 100%;
  }

  .ant-table-summary td {
    border-top: 2px solid #98A2B3;
  }

  .ant-select .ant-select-selection-placeholder {
    color: rgb(112, 126, 147) !important;
  }

  .ant-table.ant-table-small .ant-table-tbody>tr>td {
    padding: 8px 16px;
  }
}

.custom-table-timekeeping-disable {
  .ant-table-container {
    .ant-table-thead tr th {
      background-color: #667085;
    }

    .ant-table-thead tr th.clicked-column {
      padding: 0px 16px;
      background-color: #475467;
      color: rgba(255, 255, 255, 0.88);
    }
  }

  .ant-table-cell {
    background-color: #f2f4f7;
    height: 33px !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 0px 16px;

    >span{
      padding: 0;
    }
  }
 
  .ant-table-tbody .ant-table-row .ant-table-cell >span{
    padding: 0 !important;    
  }

  .cell-sunday {
    background: #e4e7ec;
  }

  .cell-L {
    background-color: #dc6803;
    color: white;

    >div {
      color: white;
    }
  }

  .cell-V {
    background-color: #d92d20;
    color: white;

    >div {
      color: white;
    }
  }

  .cell-P,
  .cell-P>div {
    background-color: #027a48;
    color: white !important;
  }

  .cell-OFF {
    background-color: #3e1c96;
    color: white;

    >div {
      color: white;
    }
  }
}

.cell-sunday {
  background: #E4E7EC !important;
}

.cell-L {
  background-color: #DC6803 !important;
  color: white;
}

.cell-L-i {
  background-color: #DC6803 !important;
  color: white !important;
}

.cell-normal > div {
  color: black
}

.cell-L-i > div {
  color: white !important;
}

.cell-no-warning > div {
  color: black !important;
}

.cell-L > div {
  color: white;
}

.cell-V {
  background-color: #D92D20 !important;
  color: white;
}

.cell-V>div {
  color: white;
}

.cell-P {
  background-color: #027A48 !important;
  color: white !important;
}

.cell-OFF {
  background-color: #3E1C96 !important;
  color: white;
}

.cell-OFF>div {
  color: white;
}

.cell-P>div {
  color: white;
}

.cell-warning>div{
  color: #D92D20
}

.merged-cell {
  border-bottom: solid 1px #667085 !important
}

.merged-cell>td {
  border-bottom: solid 1px #667085 !important
}

.ot-data-row {
  background-color: #ffffff;
  color: #DC6803 !important;
}

.ot-data-row>td {
  border-bottom: solid 1px #667085 !important
}

.editable-cell {
  position: relative;
  height: 32px;
}

.editable-cell-value-wrap {
  padding: 0 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
}

.confirm-modal-timesheet {
  .ant-modal-confirm-body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-modal-confirm-body {
      justify-content: center;
      align-items: center;

      .anticon-exclamation-circle {
        display: none;
      }

      .ant-modal-confirm-paragraph {
        text-align: center;

        .ant-modal-confirm-title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }

        .ant-modal-confirm-content {
          color: #475467;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .ant-modal-confirm-btns {
      display: flex;
      width: 100%;
      margin-top: 32px;

      .ant-btn {
        width: 50%;
        font-size: 16px;
        font-weight: 600;

        &:first-child {
          border: 1px solid #98A2B3;
          color: #475467;
        }

        &:last-child {
          background-color: #4072D0;
        }
      }
    }
  }
}